<template>
  <div
    class="dx-swatch-additional side-navigation-menu"
    @click="forwardClick"
  >
    <slot />
    <div class="menu-container ms-1">
      <dx-tree-view
        ref="treeViewRef"
        :data-source="items"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
        height="90%"
        data-structure="plain"
      />
    </div>
  </div>
</template>

<script>
import DxTreeView from "devextreme-vue/ui/tree-view";
import { sizes } from '../utils/media-query';
// import navigation from '../app-navigation';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'; 

import notify from 'devextreme/ui/notify'

import store from '../store/index.js'

import DataSource from 'devextreme/data/data_source'
import CustomStore from "devextreme/data/custom_store"

export default {
  props: {
    compactMode: Boolean
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    const isLargeScreen = sizes()['screen-large'];

    const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'

    const userPermisos = new DataSource({ 
      store: new CustomStore({
        key: 'pc_Id',
        load: async () => {
          let url = baseUrl + 'permisosConsultor/' + store.userId
          return await fetch(url)
          .then(handleErrors)
          .then(response => response.json())
          .catch(() => { throw 'Problema en la Conexión de Red'})
        }
      })
    }) 

    function handleErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response
    }

    const items = ref()

    fetch(baseUrl + '/getMenu')
      .then(handleErrors)
      .then(resp => resp.json())
      .then( async (data) => {
          await userPermisos.load()
          let resultado = data.filter( obj => permiso(obj.id)) 
          items.value = resultado.map((item) => {
            if(item.path && !(/^\//.test(item.path)))
              item.path = `/${item.path}`

            return {...item, expanded: isLargeScreen} 
          });
      })
      .catch(() => { throw 'Problema en getMenu '})

    const treeViewRef = ref(null);

    function forwardClick (...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {

      if (e.itemData.parentId != null) {
        let newTitle = ''

        newTitle = e.node.text

        if (e.node.parent != null) {
          newTitle = e.node.parent.text + ' | ' + newTitle

          if (e.node.parent.parent != null) {
            newTitle = e.node.parent.parent.text + ' | ' + newTitle

            }
          }
        store.setTitle(newTitle)
      } 
      else if (e.itemIndex == 0) // Default primera opcion de menu HOME
        store.setTitle(e.node.text)

      treeViewRef.value.instance.unselectAll()
      if (!e.itemData.path || props.compactMode) {
        return;
      }

      treeViewRef.value.instance.selectItem(e.itemData)

      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection () {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    // *************
    // * Permisos 
    // *************

    function permiso(valor) {
      let devuelve = false

      if (store.role == 'admin')
        devuelve = true
      else
        devuelve = userPermisos.items().findIndex(x => x.permiso === valor) > -1

      return devuelve
    }


    function buscaUser(id) {
    let url = baseUrl + 'user/' + id
    fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .then( data => {
            if (data[0].us_IsAdmin == 1)
              store.userSetRole('admin')
            else
              store.userSetRole('')
            
            if (data[0].us_AreaId == null) {
                notify({ message: "Debes completar tus datos de Perfil de Usuario",  shading: true, displayTime: 3500 }, { position: "center", direction: "up-push" })
                notify({ message: "Click en tu Nombre de Usuario -> Datos del Usuario", shading: true, displayTime: 4000 }, { position: "center", direction: "up-push" })
            }
        })
        .catch(() => { throw 'Problema en la Conexión de Red'})
    }

    onMounted( async () => { 

      buscaUser(store.userId)

      updateSelection();
      if (props.compactMode) {
        treeViewRef.value.instance.collapseAll();
      }
    });
    

    watch(
      () => route.path,
      () => {
        updateSelection();
      }
    );
    
    watch(
      () => props.compactMode,
      () => {
        if (props.compactMode) {
          treeViewRef.value.instance.collapseAll();
        } else {
          updateSelection();
        }
      }
    );

    return {
      treeViewRef,
      items,
      forwardClick,
      handleItemClick,
      updateSelection,
      userPermisos
    };
  },
  components: {
    DxTreeView
  }
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 280px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      //##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width - 30;
        }

        &[aria-level="3"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width - 10;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: rgb(204, 192, 192);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
    // ##
  }
}

</style>
