import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.carmine.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import {Amplify} from 'aws-amplify'
import awsConfig from './aws-exports.js'
import "@aws-amplify/ui-vue/styles.css"

// import store from './store/index.js'

Amplify.configure(awsConfig);

// const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'

// async function getRole(id) {
// if (store.role == '') {
//     await fetch(baseUrl + 'user/' + id )
//     // .then(handleErrors)
//     .then(response => response.json())
//     .then(data => {
//         if (data[0].us_IsAdmin)
//             store.userSetRole('admin')
//         else
//             store.userSetRole('none')
//     })
//     }
//     console.log('hi!! ',store.role)
// }

// getRole(1)

import App from "./App";
import appInfo from "./app-info";

themes.initialized(() => {
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
