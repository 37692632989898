export default [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    name: 'home'
  },
  {
    text: "TimeSheet",
    icon: "clock",
    name: 'timesheet',
    items: [
      {
        text: "Aprobar",
        icon: "check",
        name: 'timesheetAprobar',
        path: "/aprobar"
      },
      {
        text: "New Aprobar",
        icon: "check",
        name: 'timesheetNewAprobar',
        path: "/newaprobar"
      },
    ]
  },  
  {
    text: "Tablas",
    icon: "bulletlist",
    name: 'tablas',
    items: [
      {
        text: "Oficinas",
        path: "/oficina-tabla",
        icon: 'home',
        name: 'tablasOficina',
      },  
      {
        text: "Tareas",
        icon: 'paste',
        name: 'tablasTareas',
        items: [
          {
            text: "Tareas",
            path: "/tareas-tabla",
            icon: 'play',
            name: 'tablasTareasTareas'
          },
          {
            text: "Categorias",
            path: "/categorias-tabla",
            icon: 'play',
            name: 'tablasTareasCategorias'
          }
        ]
      },
    
      {
        text: "Clientes",
        icon: "group",
        name: 'tablasClientes',
        items: [
          {
            text: "Clientes",
            path: "/cliente-tabla",
            icon: 'play',
            name: 'tablasClientesClientes'
          },
          {
            text: "Categorias",
            path: "/clientescategoria-tabla",
            icon: 'play',
            name: 'tablasClientesCategorias'
          }
        ]
      },
      {
        text: "Usuarios",
        icon: "group",
        name: 'usuarios',
        items: [
          {
            text: "Usuarios",
            path: "/user-tabla",
            icon: 'play',
            name: 'usuariosUsuarios'
          },
          {
            text: "Categoria",
            path: "/usercategoria-tabla", 
            icon: 'play',
            name: 'usuariosCategoria'
          },
          {
            text: "Areas",
            path: "/userarea-tabla",
            icon: 'play',
            name: 'usuariosAreas'
          }
        ]
      },


    ]
  },
  {
    text: "Propuestas",
    icon: "bi bi-bookmark",
    name: 'propuestas',
    items: [
      {
        text: "Nueva Propuesta",
        path: "/propuesta-nueva",
        icon: 'bi bi-bookmark-plus',
        name: 'propuestasNueva',
      },
      {
        text: "Propuestas",
        path: "/propuesta-tabla",
        icon: 'bi bi-bookmarks',
        name: 'propuestasPropuestas',
      }
    ]
  },
  {
    text: "Proyectos",
    icon: "bi bi-bookmark-check-fill",
    name: 'proyectos',
    items: [
      {
        text: "Nuevo Proyecto",
        path: "/proyecto-nuevo",
        icon: 'bi bi-bookmark-plus-fill',
        name: 'proyectosNuevo',
      },
      {
        text: "KOM - KickOff Meeting",
        path: "/proyecto-kom",
        icon: 'bi bi-arrow-right-circle',
        name: 'proyectosKOM',
      },
      {
        text: "Cierre de Proyecto",
        path: "/proyecto-cierre",
        icon: 'bi bi-bookmark-x',
        name: 'proyectosCierre',
      },
      {
        text: "Proyectos",
        path: "/proyecto-tabla",
        icon: 'bi bi-bookmarks-fill',
        name: 'proyectosProyectos',
      },
    ]
  },
  {
    text: "Control de Proyectos",
    icon: "bi bi-card-checklist",
    name: 'control',
    items: [
      // {
      //   text: "Control Tiempo",
      //   path: "/control-tiempo",
      //   icon: 'bi bi-clock'
      // },
      // {
      //   text: "Control Documentos",
      //   path: "/control-documentos",
      //   icon: 'bi bi-files'
      // },
      {
        text: "Control Horas",
        path: "/control-horas",
        icon: 'bi bi-hourglass-split',
        name: 'controlHoras',
      },
      {
        text: "Avances",
        path: "/control-avances",
        icon: 'bi bi-arrow-right-square',
        name: 'controlAvance',
      },
      {
        text: "Indices QMS",
        icon: 'bi bi-speedometer',
        name: 'controlQMS',
        items: [
          {
            text: "Resultados",
            path: "/control-qms",
            icon: 'bi bi-clipboard2-pulse',
            name: 'controlQMSResultados',
          },
          {
            text: "QMS - Indices",
            path: "/control-qms-indices",
            icon: 'bi bi-clipboard2-pulse',
            name: 'controlQMSIndices',
          },
          {
            text: "Formularios",
            path: "/proyecto-doc",
            icon: 'bi bi-filetype-pdf',
            name: 'proyectosDocumentos',
          }
        ]
      }
    ]
  },

]
