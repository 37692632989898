
// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createMemoryHistory } from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";

import ProyectoNuevo from './views/proyecto-nuevo.vue'

import {Auth} from 'aws-amplify'
import store from './store/index.js'

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  // history: createWebHistory(),
  history: createMemoryHistory(),
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/home-page')
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/profile-page')
    },
    {
      path: "/aprobar",
      name: "aprobar",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/pendientes-ts.vue')
    },
    {
      path: "/newaprobar",
      name: "newaprobar",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/aprobar-horas.vue')
    },
    {
      path: "/auth",
      name: "auth",
      meta: {
        auth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("auth-view")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
    {
      path: "/tareas-tabla",
      name: "tareas-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/tareas-tabla')
    }, 
    {
      path: "/categorias-tabla",
      name: "categorias-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/categorias-tabla')
    }, 
    {
      path: "/oficina-tabla",
      name: "oficina-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/oficina-tabla.vue')
    }, 
    {
      path: "/usercategoria-tabla",
      name: "usercategoria-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/usercategoria-tabla.vue')
    }, 
    {
      path: "/userarea-tabla",
      name: "userarea-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/userarea-tabla.vue')
    }, 
    {
      path: "/user-tabla",
      name: "user-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/user-tabla.vue')
    }, 
    {
      path: "/clientescategoria-tabla",
      name: "clientescategoria-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/clientescategoria-tabla.vue')
    }, 
    {
      path: "/cliente-tabla",
      name: "cliente-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/cliente-tabla.vue')
    }, 
    {
      path: "/propuesta-nueva/:propuesta?",
      name: "propuesta-nueva",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/propuesta-nueva.vue')
    }, 
    {
      path: "/propuesta-tabla",
      name: "propuesta-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/propuesta-tabla.vue')
    }, 
    {
      path: "/proyecto-nuevo/:project?",
      name: "proyecto-nuevo",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: ProyectoNuevo
    }, 
    {
      path: "/proyecto-kom/:project?",
      name: "proyecto-kom",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/proyecto-kom.vue')
    }, 
    {
      path: "/proyecto-cierre/:project?",
      name: "proyecto-cierre",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/proyecto-cierre.vue')
    }, 
    {
      path: "/proyecto-tabla",
      name: "proyecto-tabla",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/proyecto-tabla.vue')
    },
    {
      path: "/proyecto-doc",
      name: "proyecto-doc",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/proyecto-doc.vue')
    }, 
    {
      path: "/control-tiempo",
      name: "control-tiempo",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-tiempo.vue')
    }, 
    {
      path: "/control-documentos",
      name: "control-documentos",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-documentos.vue')
    }, 
    {
      path: "/control-horas",
      name: "control-horas",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-horas.vue')
    }, 
    {
      path: "/control-avances",
      name: "control-avances",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-avances.vue')
    }, 
    {
      path: "/control-qms",
      name: "control-qms",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-qms.vue')
    }, 
    {
      path: "/control-qms-indices",
      name: "control-qms-indices",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/control-qms-indices.vue')
    }, 
    {
      path: "/permisos-consultor",
      name: "permisos-consultores",
      meta: {
        auth: true,
        layout: defaultLayout
      },
      component: () => import('./views/permisos-consultor.vue')
    }, 
    {
      path: "/permisos-roles",
      name: "permisos-roles",
      meta: {
        auth: true,
        layout: defaultLayout,
      },
      component: () => import('./views/permisos-roles.vue')
    }, 
    {
      path: "/consultor-tarifa",
      name: "consultor-tarifa",
      meta: {
        auth: true,
        layout: defaultLayout,
      },
      component: () => import('./views/consultor-tarifa.vue')
    }
  ]
});

const baseUrl = "https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/getuserid?cognito="

router.beforeResolve(async (to, from, next) => {
  if(to.meta.auth) {
    await Auth.currentAuthenticatedUser()
    .then(async (user) => {
      if(store.role == '') {
        store.usuarioSetValue(user.username)
        store.nombreSetValue(user.attributes.preferred_username)
        if (!user.attributes['custom:srk_id'])
          await fetch(baseUrl + user.username)
            .then(resp => resp.json())
            .then(data =>{ 
              store.userIdSetValue(data[0].us_Id)
              if (data[0].us_IsAdmin == 1)
                store.userSetRole('admin')

              Auth.updateUserAttributes(user, { 'custom:srk_id': data[0].us_Id.toString() } )
                .then(result =>{ console.log(result)})
                .catch(err => console.log(err))

            })
        else {
          store.userIdSetValue(user.attributes['custom:srk_id'])
          await fetch(baseUrl + user.username)
            .then(resp => resp.json())
            .then(data =>{ 
              if (data[0].us_IsAdmin == 1)
                store.userSetRole('admin')
              else
                store.userSetRole('none')
          })

        }
        console.log('pase por beforeResolve',store.role)
      }
    })
    .then(() => { next() })
    .catch(() => {
      next({
        path: '/auth'
      })
    })
  } else {
    next()
  }
})


export default router;
