<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
      <div class="content">
        <router-view></router-view>
      </div>
        <!-- <template #footer>
          <app-footer />
        </template> -->
      </component>
    </div>
  </div>
</template>

<script>
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from "vue";


import { Auth } from 'aws-amplify'
import store from './store/index'

const baseUrl = "https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/getuserid?cognito="

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

Auth.currentAuthenticatedUser()
  .then( async (user) => {
    store.usuarioSetValue(user.username)
    store.nombreSetValue(user.attributes.preferred_username)
    await fetch(baseUrl + user.username)
    .then(handleErrors)
    .then( resp => resp.json())
    .then( data => {
      store.userIdSetValue(data[0].us_Id)
      store.userRowModeSetValue(data[0].us_ts_RowMode)
      store.userTimeIntervalSetValue(data[0].us_ts_TimeInterval)
      store.userTimeTipoSetValue(data[0].us_ts_TimeTipo)
      if (data[0].us_IsAdmin)
        store.userSetRole('admin')
      else
        store.userSetRole('none')

    })
    .catch(() => {
    })
  })
    
//---- Localization
import esMessages from './diccionario/es.json'
import { locale, loadMessages } from "devextreme/localization";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  components: {
    AppFooter
  },
  setup() {
    const vm = getCurrentInstance();

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();
    
    function screenSizeChanged () {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
      loadMessages(esMessages)
      locale(navigator.language)
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses
    };
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
}

.dx-toolbar .dx-toolbar-label {
  font-size: 16px;
  color: white;
}



</style>
