<template>
    <DxDataGrid
        ref="myDetailDataGrid"
        :data-source="dataEquipo"
        @row-updating="updateRow"
        @init-new-row="onNewRow"
    >
        <DxColumn 
            caption="Categoría"
            data-field="te_CategoriaId"
            data-type="number"
            width=400
        >
            <DxLookup
                :data-source="dataCategoria.store()"
                value-expr="uc_Id"
                display-expr="uc_Nombre"
            />
        </DxColumn>
        <DxColumn 
            caption="Horas"
            data-field="te_Horas"
            data-type="number"
            :min-width=100
        >
        </DxColumn>
        <DxColumn 
            caption="Tarifa"
            data-field="te_Tarifa"
            data-type="number"
            format="#0.00"
            :min-width=100
        >
        </DxColumn>
        <DxColumn
            caption="Fee"
            :calculate-display-value="getFee"
            format="#0.00"
            data-type="number"
            :min-width=100
        >
        </DxColumn>
        <DxColumn
            data-field="te_Terreno"
            data-type="boolean"
            :width=50
            header-cell-template="title-header-terrain"
        >
        </DxColumn>

        <DxColumn type="buttons">
            <DxButton name="delete"/>

        </DxColumn>
        <DxEditing
            :allow-updating="true"
            :allow-deleting="true"
            :texts="localizedEditTexts"
            :use-icons="true"
            refresh-mode="repaint"
            :select-text-on-edit-start="true"
            mode="cell"
        >
        </DxEditing>
        <template #title-header-terrain>         
          <i class="bi bi-sign-intersection-t"></i>
        </template>
    </DxDataGrid>
</template>

<script setup>
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'

import { DxDataGrid,
        DxColumn, 
        DxEditing, 
        DxButton,
        DxLookup, 
    } from 'devextreme-vue/data-grid'

import { ref } from 'vue'

const myDetailDataGrid = ref(null)

const localizedEditTexts = {
    confirmDeleteMessage: '¿Esta seguro que desea eliminar esta información?'
}

const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'

const props = defineProps({
    templateData: Object,
    gridinstance: Object,
    projectId: String,
})

const emit = defineEmits(['refresca'])

const updateObject = ref()
const selectedItemKeys = ref(null)

const isNewCategoria = ref(false)

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

function upedateamos() {
    let ds = props.gridinstance.instance
    ds.refresh()
    emit('refresca')
}

const dataEquipo = new DataSource({
  store: new CustomStore({
    key: 'te_Id',
    load: () => {
      let url = baseUrl + 'tareasEquipo/' + props.projectId
      return fetch(url)
        .then(handleErrors)
        .then(resp => resp.json())
        .catch(() => { throw 'Problema en la Conexion de Red'})
    },
    update: (key) => {
        var newBody = {
            categoriaId: updateObject.value.te_CategoriaId,
            horas: updateObject.value.te_Horas,
            tarifa: updateObject.value.te_Tarifa,
            terreno: updateObject.value.te_Terreno,
            id: key
            }

        dataCategoria.store().byKey(updateObject.value.te_CategoriaId)
        .then(data => {
            if (isNewCategoria.value)
                newBody.tarifa = data[0].uc_Tarifa
        })
        .then(() => fetch(baseUrl + "tareasEquipo", {
                method: 'POST',
                body: JSON.stringify(newBody)
            })
        .then(handleErrors)
        .then(upedateamos()))

    },
    insert: (value) => {
        var newBody = {
            projectId: value.te_ProjectId,
            tareaId: value.te_TareaId,
            categoriaId: value.te_CategoriaId,
            horas: 0,
            tarifa: value.te_Tarifa,
            terreno: 0,
            }

        dataCategoria.store().byKey(value.te_CategoriaId)
            .then(data => {
                newBody.tarifa = data[0].uc_Tarifa
            })
            .then(() => fetch(baseUrl + "tareasEquipo", {
                    method: 'PUT',
                    body: JSON.stringify(newBody)
                })
            .then(handleErrors)
            .then(upedateamos()))

    },
    remove: (key) => {
        return fetch( baseUrl + "tareasEquipo?id="+key, {
            method: "DELETE"
        })
        .then(handleErrors)
        .then(upedateamos())
}
  }),
  filter: ['te_TareaId', '=', props.templateData.data.pt_Id]
})

const dataCategoria = new DataSource({
    store: new CustomStore({
        key: 'uc_Id',
        byKey: (keyVal) => {
            return fetch(baseUrl + 'usercategoria/' + keyVal)
                .then(handleErrors)
                .then(response => response.json())
                .catch(() => { throw 'Problema en la Conexión de Red - Tarica x Categoria'})
        },
        load: () => {
            let url = baseUrl + 'usercategoria/0'
            return fetch(url)
                .then(handleErrors)
                .then(response => response.json())
                .catch(() => { throw 'Problema en la Conexión de Red' + url})
        }
    })
})

async function updateRow(e) { 
    isNewCategoria.value = e.newData.te_CategoriaId != null
    if (!e.cancel) {
        for (var property in e.oldData) {
        if (!Object.prototype.hasOwnProperty.call(e.newData,property)) {
            e.newData[property] = e.oldData[property];
        }    
        }
        updateObject.value = e.newData
    }  
}

function onNewRow(e) {
  e.data.te_ProjectId = selectedItemKeys.value.te_ProjectId
  e.data.te_TareaId = selectedItemKeys.value.te_TareaId
}

function getFee(rowData) {
    return rowData.te_Tarifa * rowData.te_Horas
}

</script>